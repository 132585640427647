import { combineReducers } from 'redux'
import selectedFilters from './selectedFilters'
import mapDataReducer from './mapDataReducer'

const rootReducer = combineReducers({
    selectedFilters,
    mapDataReducer
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer