import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../reducers';

import { setFilter, setDateRange, setTab } from '../../actions';
import moment from 'moment';

import { DatePicker, Select, TreeSelect, Tabs } from 'antd';
import ThemeContext from '../../context/themeContext';

import "./Filter.scss";
import { RangePickerValue, RangePickerPresetRange } from "antd/lib/date-picker/interface";
import { SelectValue } from "antd/lib/select";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { TreeNode } = TreeSelect;
const { TabPane } = Tabs;

interface FilterProps {
    filterOpen: boolean;
    toggleFilter: React.MouseEventHandler;
}

const Filter: React.FC<FilterProps> = ({filterOpen, toggleFilter}) => {
    const dispatch = useDispatch();
    const { formSelects, mapData, defaultMapData } = useSelector((state: RootState) => state.mapDataReducer);
    const selectedFilters = useSelector((state: RootState) => state.selectedFilters);

    const defaultMinDate = new Date(Math.min(...defaultMapData.map(a => Number(new Date(a.data)))));
    const defaultMaxDate = new Date(Math.max(...defaultMapData.map(a => Number(new Date(a.data)))));

    function disabledDate(current: moment.Moment | null):boolean {
        if (current) {
            return (current < moment(defaultMinDate)) ? true : (current > moment(defaultMaxDate)) ? true : false;
        }
        return false;
    }

    function predefinedRanges() {
        const ranges:{ [range: string]: RangePickerPresetRange; } = [] as any;
        const startYear = defaultMinDate.getFullYear(),
              endYear = defaultMaxDate.getFullYear();              
        ranges[`Visi: ${startYear} - ${endYear}`] = [moment(defaultMinDate), moment(defaultMaxDate)];
        for (let year = startYear; year <= endYear; year++) {
            ranges[year] = [year === startYear ? moment(defaultMinDate) : moment(year, 'YYYY').startOf('year'), year === endYear ? moment(defaultMaxDate) : moment(year, 'YYYY').endOf('year')]
        }
        return ranges;
    }

    function handleDateRange(dates: RangePickerValue) {
        dispatch(setDateRange(dates));
    }

    function handleSelect(name: string, e: SelectValue) {
        dispatch(setFilter(name, e));
    }

    function handleTabs(key: string) {
        dispatch(setTab(key));
    }

    return (
        <ThemeContext.Consumer>
            {
                theme => (
                    <div className={`cm-filter px-4 d-md-block bg-${theme.bootstrap.bg} text-${theme.bootstrap.text} ${filterOpen ? 'open' : 'closed'}`} id="filter">
                        <div className="jumbotron p-4">
                            <h5 className="d-flex justify-content-between align-items-center mb-3">
                                <span className={`text-${theme.bootstrap.link}`}>Įvykiai:</span>
                                <span className="badge badge-primary badge-pill">{Object.values(mapData).length}</span>
                            </h5>
                            <h6 className="d-flex justify-content-between align-items-center mb-2">
                                <span className={`text-${theme.bootstrap.link}`}>Sužeista:</span>
                                <span className="badge badge-secondary badge-pill border border-secondary">{Object.values(mapData).reduce((acc, crash) => acc + parseInt(crash.suzeistu_skaicius), 0)}</span>
                            </h6>
                            <h6 className="d-flex justify-content-between align-items-center mb-0">
                                <span className={`text-${theme.bootstrap.link}`}>Žuvo:</span>
                                <span className="badge badge-light badge-pill border border-dark">{Object.values(mapData).reduce((acc, crash) => acc + parseInt(crash.zuvusiu_skaicius), 0)}</span>
                            </h6>
                        </div>
                        <div className="form-group">
                            <label htmlFor="dateFilter">Laikotarpis</label>
                            <RangePicker
                                id='dateFilter'
                                className='cm-select'
                                allowClear={false}
                                disabledDate={disabledDate}
                                ranges={predefinedRanges()}
                                value={[moment(selectedFilters.dateRange[0], 'YYYY.MM.DD'), moment(selectedFilters.dateRange[1], 'YYYY.MM.DD')]}
                                onChange={handleDateRange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="savivaldybeFilter">Savivaldybė(s)</label>
                            <Select
                                id='savivaldybeFilter'
                                mode="multiple"
                                className='cm-select'
                                placeholder="Visos"
                                value={selectedFilters.savivaldybe}
                                onChange={(v: SelectValue) => (handleSelect('savivaldybe', v))}  
                            >
                                {formSelects.savivaldybe.map(s => (
                                    <Option key={s.key}>{s.data.name}</Option>
                                ))}
                            </Select>
                        </div>
                        <Tabs defaultActiveKey="1" activeKey={selectedFilters.tab} onChange={handleTabs}>
                            <TabPane tab="Rušys" key="1">
                                <div className="form-group">
                                    {/* <label htmlFor="rusisFilter">Rušys</label> */}
                                    <Select
                                        id='rusisFilter'
                                        mode="multiple"
                                        className='cm-select'
                                        placeholder="Pasirinkite rūšis"
                                        value={selectedFilters.rusis1}
                                        onChange={(v: SelectValue) => (handleSelect('rusis1', v))}
                                    >
                                        {formSelects.rusis1.map(s => (
                                            <Option key={s.key}>{s.data.name}</Option>
                                        ))}
                                    </Select>
                                </div>
                            </TabPane>
                            <TabPane tab="Detalus filtras" key="2">
                                <div className="form-group">
                                    {/* <label htmlFor="rusisFilterTree">Detalus filtras</label> */}
                                    <TreeSelect                                
                                        id='rusisFilterTree'
                                        className='cm-select'
                                        value={selectedFilters.rusisTree}
                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                        getPopupContainer={() => document.getElementById('filter') as HTMLElement}
                                        placeholder="Pasirinkite kategorijas"
                                        allowClear={false}
                                        multiple
                                        treeDefaultExpandAll
                                        dropdownMatchSelectWidth 
                                        treeCheckable ={true}
                                        showCheckedStrategy={TreeSelect.SHOW_PARENT}
                                        onChange={(v: SelectValue) => (handleSelect('rusisTree', v))}
                                    >
                                        {formSelects.rusis2.map(r2 => (
                                            <TreeNode value={`rusis2-${r2.key}`} title={r2.data.name} key={`0-${r2.key}`}>
                                                {formSelects.rusis3.map(r3 => (
                                                    (r3.data.rusis2_id === r2.key) && <TreeNode value={`rusis3-${r3.key}`} title={r3.data.name} key={`1-${r3.key}`}></TreeNode>
                                                ))}
                                            </TreeNode>
                                        ))}
                                    </TreeSelect>
                                </div>
                            </TabPane>
                        </Tabs>

                        <button className="btn btn-success d-md-none mt-3" onClick={toggleFilter}>Rodyti žemėlapį</button>      
                        
                    </div>
                )
            }
        </ThemeContext.Consumer>
    );
}

export default Filter;