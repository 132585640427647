import { applyMiddleware, compose, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import monitorReducerEnhancer from './enhancers/monitorReducer'
//import loggerMiddleware from './middleware/logger'
import rootReducer from './reducers'

export default function configureStore(preloadedState) {
  const middlewares = [/*loggerMiddleware, */thunkMiddleware]
  const middlewareEnhancer = applyMiddleware(...middlewares)

  const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      stateSanitizer: (state) => state.mapDataReducer.mapData ? { 
        ...state, 
        mapDataReducer: { 
          ...state.mapDataReducer, 
          defaultMapData: [...state.mapDataReducer.defaultMapData.splice(0, 1)],
          mapData: [...state.mapDataReducer.mapData.splice(0, 1)],
        }} : state
    }) : compose;

  const enhancers = [middlewareEnhancer, monitorReducerEnhancer]
  const composedEnhancers = composeEnhancers(...enhancers)  
  
  const store = createStore(rootReducer, preloadedState, composedEnhancers)

  return store
}