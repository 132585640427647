import React from "react";
import Toggle from "react-toggle";
import ThemeContext from "../../context/themeContext";
import { showCookiePolicyModal } from "../CookieConsent/CookieConsent";
import { Modal } from "antd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun, faMoon } from "@fortawesome/free-solid-svg-icons";

import "react-toggle/style.css";
import "./Footer.scss";

export const showDataModal = () =>
  Modal.info({
    title: "Duomenų šaltiniai",
    content: (
      <div>
        <p>
          Eismo įvykių, įvykusių Lietuvos Respublikoje, kurių metu žuvo ir (ar)
          buvo sužeisti žmonės:
          <br />
          Policijos departamentas prie Vidaus reikalų ministerijos (Atviri
          duomenys, pateikiami pagal{" "}
          <a
            href="https://creativecommons.org/licenses/by/4.0/deed.lt"
            target="_blank"
            rel="noopener noreferrer"
          >
            Creative Commons Attribution 4.0
          </a>{" "}
          licenciją)
        </p>
        <p>
          Dalis duomenų apie eismo įvykių vietą yra patikslinti
          ivykiuzemelapis.lt autoriaus.
        </p>
      </div>
    ),
    onOk() {},
  });

interface FooterProps {
  onThemeSwitcherClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Footer = ({ onThemeSwitcherClick }: FooterProps) => {
  const darkPreffered =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches;

  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <footer
          className={`footer py-2 bg-${theme.bootstrap.bg} text-${theme.bootstrap.text}`}
        >
          <div className="container">
            <small>
              ©
              <a
                className={`text-${theme.bootstrap.link} px-3`}
                href="mailto:ivykiuzemelapis@gmail.com"
                rel="noopener noreferrer"
              >
                Kontaktai
              </a>
              |
              <a
                href="#cookies"
                className={`text-${theme.bootstrap.link} px-3`}
                onClick={showCookiePolicyModal}
              >
                Slapukų politika
              </a>
              |<span className="px-3">Duomenys atnaujinti: 2020.01</span>|
              <a
                href="#data"
                className={`text-${theme.bootstrap.link} px-3`}
                onClick={showDataModal}
              >
                Duomenų šaltiniai
              </a>
            </small>

            <Toggle
              className="ml-lg-3 float-right"
              defaultChecked={darkPreffered ? true : false}
              icons={{
                checked: <FontAwesomeIcon icon={faMoon} color="white" />,
                unchecked: <FontAwesomeIcon icon={faSun} color="white" />,
              }}
              onChange={onThemeSwitcherClick}
            />
          </div>
        </footer>
      )}
    </ThemeContext.Consumer>
  );
};

export default Footer;
