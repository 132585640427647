import SelectedFiltersState from '../model/SelectedFiltersState';
import moment from 'moment';

const selectedFilters = (state: SelectedFiltersState = {
  dateRange: [moment('2015-01-01'), moment('2019-12-31')],
  tab: '1'
}, action: any): SelectedFiltersState => {
  switch (action.type) {
    case 'SET_FILTER':
      if (action.items.includes("0") || action.items.includes("rusis2-0") || action.items.length < 1) {
        //@ts-ignore
        const { [action.name]: value, ...stateWithoutName } = state
        //@ts-ignore
        return stateWithoutName
      } else {
        return Object.assign({}, state, {
          [action.name]: action.items
        })
      }    
    case 'SET_DATES':
      return Object.assign({}, state, {
        dateRange: action.dates
      })
    case 'SET_TAB':
      return Object.assign({}, state, {
        tab: action.tab
      })
    default:
      return state
  }
}
export default selectedFilters