import fetch from 'cross-fetch'
import moment from 'moment'

import { Action } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

import { REQUEST_DATA, RECEIVE_DATA, FILTER_DATA } from "./types"
import { RootState } from '../reducers'
import MapData from '../model/MapData'
import AllSelects, { Filter, Option, FilterData } from '../model/AllSelects'
import SelectedFiltersState from '../model/SelectedFiltersState'

import { SelectValue } from "antd/lib/select";

interface JsonData {
    filterData: FilterData;
    mapData: MapData[];
}

type Dsp = ThunkDispatch<RootState, void, Action>

function requestData() {
    return {
        type: REQUEST_DATA
    }
}

//prepare Options for Selects
function prepareOptions(state: RootState, json: JsonData) {
    const defaultFilterOptions = state.mapDataReducer.formSelects
    let filteredData:AllSelects = [] as any;
    for (const [filterName, optionsObj] of Object.entries(json.filterData)) {
        if (defaultFilterOptions.hasOwnProperty(filterName)) {
            const s: Option[] = Object.entries(optionsObj).map(([k, v]) => ({ key: k, data: v as Filter })) //v is object too
            const firstOption: Option = defaultFilterOptions[filterName][0];
            s.unshift(firstOption) //add default first option 'Visos'
            filteredData[filterName] = s;
        }
    }
    return filteredData
}

function receiveData(dispatch: Dsp, state: RootState, json: JsonData) {
    const filteredData = prepareOptions(state, json)
    //get min and max dates for dateRange
    const mapData = json.mapData
    const minDate = new Date(Math.min(...mapData.map(a => Number(new Date(a.data)))))
    const maxDate = new Date(Math.max(...mapData.map(a => Number(new Date(a.data)))))
    //@ts-ignore
    dispatch(setFilters('dateRange', [moment(minDate), moment(maxDate)]))

    return {
        type: RECEIVE_DATA,
        defaultMapData: mapData,
        formSelects: filteredData,
        receivedAt: Date.now()
    }
}

function filterData(state: RootState) {
    let data = state.mapDataReducer.defaultMapData
    const filters = state.selectedFilters
    for (const [key, values] of Object.entries(filters)) {
        if (key !== 'tab') {
            if (key === 'dateRange') {
                data = data.filter(i => {
                    const mdate = moment(i['data'], 'YYYY-MM-DD')
                    return mdate >= values[0] && mdate <= values[1]
                })
            } else {
                let sumFilteredPropsForSelect = [];
                for (const value of values) {
                    if (key === 'rusisTree') {
                        const [table, index] = value.split('-')
                        sumFilteredPropsForSelect.push(...data.filter(i => i[table + '_id'] === index))
                    } else {
                        sumFilteredPropsForSelect.push(...data.filter(i => i[key + '_id'] === value))
                    }
                }
                data = sumFilteredPropsForSelect;
            }
        }
    }
    return {
        type: FILTER_DATA,
        mapData: data
    }
}

function fetchData(state: RootState) {
    return async (dispatch: Dsp) => {
        dispatch(requestData())
        const response = await fetch(`/api/area`)
        const json = await response.json()
        return dispatch(receiveData(dispatch, state, json))
    }
}

function shouldFetchData(state: RootState) {
    const data = state.mapDataReducer.defaultMapData
    if (data.length < 1) {
        return true
    } else if (state.mapDataReducer.isFetching) {
        return false
    } else {
        return false
    }
}

export function fetchDataIfNeeded() {
    return (dispatch: Dsp, getState: () => RootState) => {
        if (shouldFetchData(getState())) {
            // Dispatch a thunk from thunk!
            return dispatch(fetchData(getState()))
        } else {
            return Promise.resolve()
        }
    }
}

/* filter actions */

function setFilters(name: string, items: SelectValue) {
    return {
        type: 'SET_FILTER',
        name: name,
        items: items
    }
}

export function setFilter(name: string, items: SelectValue) {
    return (dispatch: Dsp, getState: () => RootState) => {
        dispatch(setFilters(name, items))
        dispatch(filterData(getState()))
    }
}

function setDates(dates: SelectedFiltersState['dateRange']) {
    return {
        type: 'SET_DATES',
        dates: dates
    }
}

export function setDateRange(dates: SelectedFiltersState['dateRange']) {
    return (dispatch: Dsp, getState: () => RootState) => {
        dispatch(setDates(dates))
        dispatch(filterData(getState()))
    }
}

export function setTab(key: string) {
    return {
        type: 'SET_TAB',
        tab: key
    }
}