import L from 'leaflet';
import heat from './HeatLayer';
import { style } from '../../context/themeContext';

export function drawCircle(circlesLayer, crash, formSelects) {
    let circle = L.circleMarker([crash.lat, crash.lng], {
        stroke: false,
        fillColor: (parseInt(crash.rusis1_id) === 1) ? style.circles.pedestrian : (parseInt(crash.rusis1_id) === 3) ? style.circles.bicycle : style.circles.default, //pestysis, dviratis, auto
        fillOpacity: 0.7,
        radius: 5
    });
    circle.addTo(circlesLayer);

    circle.setStyle({ radius: 5 + (parseInt(crash.suzeistu_skaicius) + parseInt(crash.zuvusiu_skaicius)) * 2 });
    (crash.zuvusiu_skaicius > 0) && circle.setStyle({ stroke: true, color: 'black' });

    bindPopup(circle, crash, formSelects);
}

//<br><button type="button" class="btn btn-primary btn-sm">Detaliau</button>

function bindPopup(circle, crash, formSelects) {
    circle.bindPopup(
        `${crash.data.replace(/-/g, '.')} ${crash.laikas.slice(0, -3)} <br />        
            ${formSelects.rusis1.find(x => x.key === crash.rusis1_id).data.name} <br />
            Kodas: ${crash.kodas} <br />
            ${(crash.suzeistu_skaicius > 0) ? "Sužeista: " + crash.suzeistu_skaicius + "<br />" : ""}
            ${(crash.zuvusiu_skaicius > 0) ? "Žuvo: " + crash.zuvusiu_skaicius + "<br />" : ""}              
          `);
}

export function drawHeatmap(map, heatmapLayer, heatData) {
    let h = heat(heatData, {
        radius: 4, gradient: {
            0.0: 'white',
            0.5: '#fb8200',
            0.8: 'red',
            1.0: 'yellow'
        }, blur: 5, minOpacity: 0.5
    });
    h.addTo(heatmapLayer);

    map.on('zoomend', function (ev) {
        let zoom = map.getZoom();
        let rad = () => {
            return (zoom <= 7) ? 4 :
                (zoom === 8) ? 5 :
                    (zoom === 9) ? 6 :
                        (zoom === 10) ? 7 :
                            (zoom === 11) ? 8 :
                                (zoom === 12) ? 9 :
                                    (zoom === 13) ? 10 :
                                        (zoom === 14) ? 11 :
                                            (zoom === 15) ? 12 :
                                                (zoom === 16) ? 15 :
                                                    (zoom === 17) ? 17 :
                                                        (zoom === 18) ? 19 : 20;
        }
        //console.log(`map zoom ${zoom}, heatmap radius ${rad()-2}`);
        h.setOptions({
            radius: rad() - 2
        });
    });

}

//some tips:
//circlesLayer.getLayers().filter(l=>l instanceof L.circleMarker).forEach(l=>map.remove(l));
/* map.eachLayer(function(layer) {
  if (layer instanceof L.featureGroup) featureGropus.push(layer);
}); */

/* just for debug
map.on('click', function(e) {
    console.log("Lat, Lng : " + e.latlng.lat + ", " + e.latlng.lng)
});
*/

export function fitBounds(map, circlesLayer, maxZoom) {
    //count
    const markers = circlesLayer.getLayers();

    //auto-zoom to fit all the data
    let bounds = L.latLngBounds();
    if (markers.length > 1) {
      bounds = circlesLayer.getBounds();
    } else if (markers.length === 1) {
      const [marker] = markers;
      bounds = marker.getLatLng().toBounds(1); //spread the bounds to 1m, otherwise fitBounds throws an error
    }
    Object.keys(bounds).length > 1 && map.fitBounds(bounds, { maxZoom: maxZoom });
}