import React from 'react';

/**
 * based on https://github.com/ericnjanga/React-Theme-Switcher
 */

export const style = {
  circles: {
    pedestrian: '#0e7bc1',
    bicycle: '#5abd43',
    default: '#fb8200'
  },
  input: {
    primary: '#6c757d'
  }
};

export const themeStyle = {
  light: { 
    mainContainer: {
        className: 'cm-light',
    },
    navbar: {
      theme: 'light',
      bg: 'white',
    },
    bootstrap: {
      bg: 'white',
      text: 'body',
      link: 'secondary',
    },
    map: {
      tiles: 'light',
      overlay: 'light',
      spin: 'black'      
    },
    antd: 'light'
  },
  dark: {
    mainContainer: {
        className: 'cm-dark',
    },
    navbar: {
      theme: 'dark',
      bg: 'dark',
    },
    bootstrap: {
      bg: 'dark',
      text: 'white-50',
      link: 'light'
    },
    map: {
      tiles: 'dark',
      overlay: 'dark',
      spin: 'white'
    },
    antd: 'dark'
  }
};

const ThemeContext = React.createContext(themeStyle.light);

export default ThemeContext;