import React from 'react';
import { useDispatch } from 'react-redux';
import { setFilter } from '../../actions';

import ThemeContext from '../../context/themeContext';
import logoPng from '../../static/favicon-32x32.png';

import "./Navbar.scss";

interface NavbarProps {
  toggleFilter: React.MouseEventHandler;
}

const Navbar = ({toggleFilter}: NavbarProps) => {
  const dispatch = useDispatch();

  function handleClick(e: React.MouseEvent, name: string, items: string[]) {
    e.preventDefault();
    dispatch(setFilter(name, items));
  }

  return (
    <ThemeContext.Consumer>
      {
        theme => (
          <nav className={`navbar navbar-expand-lg navbar-${theme.navbar.theme} bg-${theme.navbar.bg}`}>
            <div className="container">
              <a className="navbar-brand" href="/"><img src={logoPng} className="mr-2" alt="" /> Eismo įvykių žemėlapis</a>

              <button className="navbar-toggler d-md-none" onClick={toggleFilter}>
                <span className="navbar-toggler-icon"></span>
              </button>

              <div className="d-none d-md-block">
                <ul className="navbar-nav ml-auto flex-row">
                  <li className="nav-item">
                    <a className="nav-link px-2" href="/" onClick={e => handleClick(e, 'savivaldybe', ['0'])}>Lietuva</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link px-2" href="/vilnius" onClick={e => handleClick(e, 'savivaldybe', ['57'])}>Vilnius</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link px-2" href="/kaunas" onClick={e => handleClick(e, 'savivaldybe', ['15'])}>Kaunas</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link px-2" href="/klaipeda" onClick={e => handleClick(e, 'savivaldybe', ['20'])}>Klaipėda</a>
                  </li>
                </ul>

              </div>
            </div>
          </nav>
        )
      }
    </ThemeContext.Consumer>
  );

};

export default Navbar;