import {
    REQUEST_DATA,
    RECEIVE_DATA,
    FILTER_DATA,
    MapDataActionTypes
} from '../actions/types'

import AllSelects from '../model/AllSelects'
import MapDataState from '../model/MapDataState'

const defaultOption = [{ key: '0', data: { name: 'Visos' } }]
const defaultSelects: AllSelects = {
    savivaldybe: defaultOption,
    rusis1: defaultOption,
    rusis2: defaultOption,
    rusis3: defaultOption
}

function mapDataReducer(
    state: MapDataState = {
      isFetching: false,
      defaultMapData: [],
      mapData: [],
      formSelects: defaultSelects,
      lastUpdated: null
  },
    action: MapDataActionTypes
  ): MapDataState  {
    switch (action.type) {
      case REQUEST_DATA:
        return Object.assign({}, state, {
          isFetching: true
        })
      case RECEIVE_DATA:
        return Object.assign({}, state, {
          isFetching: false,
          defaultMapData: action.defaultMapData,
          mapData: action.defaultMapData,
          formSelects: action.formSelects,
          lastUpdated: action.receivedAt
        })
      case FILTER_DATA:
        return Object.assign({}, state, {
          mapData: action.mapData
        })
      default:
        return state
    }
  }

export default mapDataReducer