import React from "react";
import { useCookies } from "react-cookie";
import ThemeContext from "../../context/themeContext";
import { Alert, Modal } from "antd";

import "./CookieConsent.scss";

/* https://www.isic.lt/slapuku-naudojimas/ */
export const showCookiePolicyModal = () =>
  Modal.info({
    title: "Slapukų naudojimas",
    content: (
      <div>
        <p>
          ivykiuzemelapis.lt svetainėje naudojame slapukus (angl. cookies),
          todėl čia Jums pateiksime išsamią informaciją apie tai, kaip slapukai
          naudojami ir kaip Jums apsispręsti ar slapukus palikti, ar pašalinti.
          Mūsų naudojami slapukai nesaugo jokios jūsų asmeninės informacijos ir
          neperduoda trečioms šalims informacijos, kuri leistų jus
          identifikuoti.
        </p>
        <p>
          <strong>KAS YRA SLAPUKAS?</strong>
          <br />
          Slapukais vadinami informacijos elementai, perkeliami iš interneto
          svetainės į Jūsų kompiuterį. Tai nedideli informacijos failai, kurie
          leidžia interneto svetainėms išsaugoti ir vėl pasiekti informaciją
          jūsų naršyklėje. Slapukus naudoja dauguma interneto svetainių, nes jie
          yra viena iš technologinių priemonių, padedančių pritaikyti interneto
          turinį prie naudotojų poreikių ar asmeninių pasirinkimų. Slapukai
          leidžia interneto svetainėms teikti prie naudotojų poreikių
          pritaikytas paslaugas (pvz. įsimenant prisijungimo duomenis, išlaikant
          pirkinius pirkinių krepšelyje arba rodant tik konkretų naudotoją
          dominantį turinį). Slapukų būna skirtingų: laikinieji slapukai (arba
          seanso slapukai) – pašalinami, kai užveriama naršyklė; nuolatiniai
          slapukai – nepašalinami ir lieka tol, kol pašalinami aktyviais
          veiksmais arba kol baigiasi jų galiojimas.
        </p>
        <p>
          <strong>KAIP PAŠALINTI SLAPUKĄ?</strong>
          <br />
          Daugelis naršyklių nustatytos taip, kad automatiškai priimtų slapukus.
          Galite naršyklėje slapukus išjungti, bet greičiausia neteksite
          galimybės naudotis daugybe funkcijų, kurios būtinos, kad interneto
          svetainė veiktų kaip dera. Jeigu jums reikia išsamesnės informacijos
          apie slapukus, apsilankykite interneto svetainėje
          www.aboutcookies.org, kur rasite išsamios, nepriklausomos informacijos
          apie tai, kaip išjungti slapukus per naršyklės nuostatas ir kaip
          pašalinti slapukus, kurie jau yra jūsų kompiuteryje. Kad pašalintumėte
          slapukus iš mobiliojo telefono, turėsite pasiskaityti telefono
          naudojimo instrukciją.
        </p>
        <p>
          <strong>SLAPUKŲ TIPAI</strong>
          <br />* Būtinieji: tokie slapukai yra būtini tam, kad galėtumėte
          žvalgytis po interneto svetainę ir naudotis jos funkcijomis, tokiomis
          kaip prieiga prie saugių interneto svetainės sričių. Be šių slapukų
          neveiktų visa ar dalis svetainės.
          <br />* Veikimą gerinantys: šie slapukai padeda surinkti statistinę
          apibendrintą informaciją apie tai, kaip naudojatės tam tikra interneto
          svetaine, pavyzdžiui, kurie puslapiai lankomi dažniausiai, ar tinkamai
          veikia svetainė ir ar lankytojai iš tinklapių gauna klaidų pranešimų.
          Šie slapukai nerenka informacijos, leidžiančios atpažinti svetainės
          lankytoją. Visa šių slapukų renkama informacija yra bendro pobūdžio,
          todėl anoniminė. Ji naudojama tik svetainės veiklai pagerinti.
          <br />* Funkciniai: šie slapukai leidžia interneto svetainei įsiminti
          jūsų pasirinktus dalykus (pvz.: jūsų pasirinkta kalba ar regionas) ir
          pasiūlyti patobulintas, jums asmeniškai pritaikytas funkcijas. Šių
          slapukų renkamą informaciją galima padaryti anoniminę, be to, jų
          negali naudoti kitos svetainės, kuriose galite lankytis.
          <br />* Tiksliniai: šie slapukai gali būti naudojami jūsų interesus
          atitinkančių reklaminių skelbimų rodymui arba statistinės informacijos
          rinkimui. Reklamos tinklai paprastai naudoja tikslinius slapukus.
        </p>
        <p>
          <strong>
            AR GALIU LEISTI NAUDOTI SLAPUKUS IR NARŠYTI ANONIMIŠKAI?
          </strong>
          <br />
          Taip. Visos šiuolaikinės naršyklės turi „Anoniminį langą“ arba
          „Incognito window“. Jeigu naršysite anoniminiame lange, visi slapukai
          bus pašalinti iš jūsų naršyklės uždarius naršyklės langą. Svetainės
          jūsų nustatymų ar naršyklės neįsimins, bet funkcionuos be trikdžių.
        </p>
        <p>
          <strong>AR GALIU ATSISAKYTI SUASMENINTOS REKLAMOS?</strong>
          <br />
          Dauguma reklamos tinklų yra susijungę į organizacijas, kurios
          centralizuotai valdo vartotojų pasirinkimus. Apsilankykite
          http://optout.aboutads.info ir pasirinkite ką norite leisti ar
          drausti.
        </p>
        <p>
          <strong>„GOOGLE ANALYTICS“ SLAPUKAI</strong>
          <br />
          Absoliuti dauguma Lietuvos svetainių naudojasi „Google Analytics“
          paslaugomis rinkti ir analizuoti svetainių veikimo duomenis, stebėti
          puslapio krovimosi laikus, puslapių lankomumą, lankytojų veiksmus.
          Dauguma tų duomenų yra anoniminiai, tik atskirais specialiais atvejais
          gali rinkti jus identifikuojančią informaciją (pvz.: jūsų vartotojo
          ID, jeigu lankotės elektroninėse parduotuvėse, kuriose reikia
          prisijungti). Plačiau apie „Google Analytics“ slapukus
          skaitykite&nbsp;
          <a
            href="https://policies.google.com/technologies/types?hl=lt"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://policies.google.com/technologies/types?hl=lt
          </a>
        </p>
        <p>
          Norėdami apsaugoti savo privatumą nuo Google, galite pasinaudoti
          Google Analytics suprogramuotu įrankiu, kuris blokuoja bet kokios
          informacijos perdavimą į Google Analytics serverius. Jis veikia
          daugumoje šiuolaikinių naršyklių.&nbsp;
          <a
            href="https://tools.google.com/dlpage/gaoptout"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://tools.google.com/dlpage/gaoptout
          </a>
        </p>
        <p>
          Tikimės ši informacija Jums bus naudinga. Jeigu turite klausimų dėl
          asmens duomenų apsaugos ar privatumo nuostatų, rašykite el. pašto
          adresu ivykiuzemelapis@gmail.com.
        </p>
      </div>
    ),
    onOk() {},
  });

const CookieConsent = () => {
  const [cookies, setCookie] = useCookies(["cm_consent"]);

  const onClose = (e: any) => {
    setTimeout(() => setCookie("cm_consent", "1", { path: "/" }), 1000);
  };

  return !cookies.cm_consent ? (
    <ThemeContext.Consumer>
      {(theme) => (
        <Alert
          className={`cm-alert bg-${theme.bootstrap.bg} text-${theme.bootstrap.text}`}
          message={
            <>
              Svetainėje naudojami slapukai, kurie padeda užtikrinti jums
              teikiamų paslaugų kokybę. Tęsdami naršymą, jūs sutinkate su{" "}
              <a
                href="#cookies"
                className={`text-${theme.bootstrap.link}`}
                onClick={showCookiePolicyModal}
              >
                slapukų politika
              </a>
              .
            </>
          }
          type="info"
          showIcon
          closable
          onClose={onClose}
        />
      )}
    </ThemeContext.Consumer>
  ) : null;
};

export default CookieConsent;
