import MapData from '../model/MapData';
import SelectState from '../model/AllSelects';

export const REQUEST_DATA = 'REQUEST_DATA';
export const RECEIVE_DATA = 'RECEIVE_DATA';
export const FILTER_DATA = 'FILTER_DATA';

interface SendDataAction {
    type: typeof REQUEST_DATA;
}

interface ReceiveDataAction {
    type: typeof RECEIVE_DATA;
    defaultMapData: MapData[];
    formSelects: SelectState;
    receivedAt: Date;
}

interface FilterDataAction {
    type: typeof FILTER_DATA;
    mapData: MapData[];
}

export type MapDataActionTypes = SendDataAction | ReceiveDataAction | FilterDataAction;